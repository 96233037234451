import React, { useEffect } from "react";
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import axios from "axios";

const ReloginWrapper = ({ children }) => {
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const performRelogin = async () => {
      if (!isAuthenticated()) {
        const rememberMe = localStorage.getItem("rememberme");
        const storedUsername = localStorage.getItem("username");
        const storedPassword = localStorage.getItem("password");

        if (storedUsername && storedPassword && rememberMe === "true") {
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_BASE + "/LoginUser",
              { username: storedUsername, password: storedPassword }
            );

            // Assuming the relogin was successful
            await signIn({
              token: response.data.resultMessage.split(" ")[3],
              expiresIn: 60,
              tokenType: "Bearer",
              authState: rememberMe
                ? { username: storedUsername, password: storedPassword }
                : { username: storedUsername },
            });

          } catch (error) {
            console.error("Relogin failed:", error);
          }
        }
      }
    };

    performRelogin();
  }, [signIn, isAuthenticated]);

  // Render children when relogin logic is done
  return <>{children}</>;
};

export default ReloginWrapper;
