import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import { Container } from "react-bootstrap";
import { useIsAuthenticated } from "react-auth-kit";
import PrimeReact, { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
        
import "./custom-bootstrap.scss";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Company from "./pages/Company";
import Plant from "./pages/Plant";
import Invalid from "./pages/Invalid";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import ReloginWrapper from "./components/ReloginWrapper";
import Registration from "./pages/Register";
import RegistrationSuccess from "./pages/RegistrationSuccess";
import RegistrationConfirmation from "./pages/RegistrationConfirmation";

const App = () => {
  const isAuthenticated = !useIsAuthenticated();

  useEffect(() => {
    const isDarkTheme =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    // Set the attribute on the <html> tag
    if (isDarkTheme) {
      document.documentElement.setAttribute("data-bs-theme", "dark");
      import(`primereact/resources/themes/md-dark-deeppurple/theme.css`)
    } else {
      document.documentElement.setAttribute("data-bs-theme", "light");
      import(`primereact/resources/themes/md-light-deeppurple/theme.css`)
    }
    // Clean up the attribute when the component is unmounted
  }, []);

  const styles = {
    content: {
      marginTop: 0,
      padding: 0,
      marginBottom: "12px",
      height: "calc(100vh - 140px)",
      overflow: "scroll",
    },
  };

  return (
    <Router>
      <ReloginWrapper>
        <NavigationBar />
        <PrimeReactProvider>
          <Container fluid style={styles.content}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/login"
                element={
                  isAuthenticated ? <Navigate to="/" replace /> : <Login />
                }
              />
              <Route
                path="/register"
                element={
                  isAuthenticated ? (
                    <Navigate to="/" replace />
                  ) : (
                    <Registration />
                  )
                }
              />
              <Route
                path="/registrationSuccess"
                element={
                  isAuthenticated ? (
                    <Navigate to="/" replace />
                  ) : (
                    <RegistrationSuccess />
                  )
                }
              />
              <Route
                path="/confirmEmail"
                element={
                  isAuthenticated ? (
                    <Navigate to="/" replace />
                  ) : (
                    <RegistrationConfirmation />
                  )
                }
              />
              <Route
                path="/company"
                element={
                  <RequireAuth loginPath={"/login"}>
                    <Company />
                  </RequireAuth>
                }
              />
              <Route
                path="/plant"
                element={
                  <RequireAuth loginPath={"/login"}>
                    <Plant />
                  </RequireAuth>
                }
              />
              <Route path="*" element={<Invalid />} />
            </Routes>
          </Container>
        </PrimeReactProvider>
        <Footer />
      </ReloginWrapper>
    </Router>
  );
};

export default App;
