import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuthHeader } from "react-auth-kit";
import { useSearchParams, Link } from "react-router-dom";
import { SlEnergy } from "react-icons/sl";
import { FaEuroSign } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import * as lightTheme from "highcharts/themes/high-contrast-light";
import * as darkTheme from "highcharts/themes/high-contrast-dark";
import { MdOutlineSolarPower } from "react-icons/md";
import { BiBarChartAlt2 } from "react-icons/bi";
import { BsHddStack } from "react-icons/bs";
import PVAnimation from "../components/PVAnimation";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { env } from '../env'

import "./Plant.css";
import "./Company.css";

highchartsMore(Highcharts);
solidGauge(Highcharts);

function Plant() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isMonthlyLoading, setIsMonthlyLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [monthlyDetails, setMonthlyDetails] = useState([]);
  const [totalMonthlyProduction, setTotalMonthlyProduction] = useState(0);
  const [totalMonthlyRevenue, setTotalMonthlyRevenue] = useState(0);
  const [monthlyDetailsLoaded, setMonthlyDetailsLoaded] = useState(false);
  const [plantData, setPlantData] = useState([]);
  const [powerData, setPowerData] = useState([]);
  const [date, setDate] = useState(new Date());
  const [minDate, setMinDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [maxDate, setMaxDate] = useState(new Date().setHours(23, 59, 59, 999));
  const [devicesList, setDevicesList] = useState([]);
  const [devicesListLoaded, setDevicesListLoaded] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedInverter, setSelectedInverter] = useState(null);
  const [selectedString, setSelectedString] = useState(null);
  const [selectedInverterLogs, setSelectedInverterLogs] = useState(null);

  const auth = useAuthHeader();

  const config = {
    headers: {
      Token: auth().split(" ")[1],
    },
  };

  async function fetchPlantData() {
    axios
      .get(
        env.REACT_APP_API_BASE +
          "/GetPlant?id=" +
          searchParams.get("id"),
        config
      )
      .then((response) => {
        let tmp = response.data;
        setPlantData(tmp[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1
  const year = date.getFullYear();

  // Format the date as dd/MM/YYYY
  const formattedDate = `${day}/${month}/${year}`;
  const formattedMonthDate = `${month}/${year}`;

  function daysInMonth() {
    return new Date(year, month, 0).getDate();
  }

  async function fetchPowerData() {
    axios
      .get(
        env.REACT_APP_API_BASE +
          "/GetProduction?plantId=" +
          searchParams.get("id") +
          "&type=daily&parameter=" +
          formattedDate,
        config
      )
      .then((response) => {
        let tmp = response.data;
        setPowerData(tmp);
        setIsDataLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsDataLoading(false);
      });
  }

  async function fetchMonthlyData() {
    axios
      .get(
        env.REACT_APP_API_BASE +
          "/GetProduction?plantId=" +
          searchParams.get("id") +
          "&type=monthlySummary&parameter=" +
          formattedMonthDate,
        config
      )
      .then((response) => {
        if (response.data.length === 0) {
          setMonthlyData({
            forMonth: year+"-"+month+"-01T00:00:00",
            plantID: 1,
            mwh: 0,
            eur: 0,
          });
        }
        else
          setMonthlyData(response.data[0]);
        console.log(monthlyData)
        setIsMonthlyLoading(false);
      });
  }

  async function fetchMonthlyDetailsData() {
    setMonthlyDetailsLoaded(false);
    let i = 1;
    let tmp = [];
    let dailyLogs = await axios.get(
      env.REACT_APP_API_BASE +
        "/GetProduction?plantId=" +
        searchParams.get("id") +
        "&type=monthly&parameter=" +
        formattedMonthDate,
      config
    );

    let dailyDetails = dailyLogs.data;

    for (; i <= daysInMonth(); i++) {
      let pushed = false;
      dailyDetails.forEach((dailyLog) => {
        let day = parseInt(dailyLog.forDate.split("T")[0].split("-")[2]);
        if (i === day && !pushed) {
          tmp.push(dailyLog);
          pushed = true;
        }
      });
      if (!pushed) {
        let iString = i < 10 ? "0" + i : i;
        tmp.push({
          forDate:
            "2023-" + month.padStart(2, "0") + "-" + iString + "T00:00:00",
          generatedEnergy: 0,
          generatedRevenue: 0,
        });
      }
    }

    tmp = tmp.sort(function compareDates(a, b) {
      let aDate = new Date(a.forDate);
      let bDate = new Date(b.forDate);
      return aDate - bDate; // Use subtraction to compare dates
    });

    let tmpProduction = 0;
    let tmpRevenue = 0;
    tmp.forEach(day => {
      console.log(day.generatedEnergy)
      tmpProduction+=parseFloat(day.generatedEnergy)
      tmpRevenue+=parseFloat(day.generatedRevenue)
    })

    setTotalMonthlyProduction(tmpProduction + calculateDailyPower())
    setTotalMonthlyRevenue(tmpRevenue + calculateDailyRevenue())

    console.log(tmp);

    setMonthlyDetails(tmp);
    setMonthlyDetailsLoaded(true);
  }

  const fetchDevicesData = async () => {
    setDevicesListLoaded(false);

    try {
      const devicesResponse = await axios.get(
        `${
          env.REACT_APP_API_BASE
        }/GetDevices?PlantId=${searchParams.get("id")}`,
        config
      );
      const devices = devicesResponse.data;

      const finalDevices = await Promise.all(
        devices.map(async (device) => {
          const invertersResponse = await axios.get(
            `${env.REACT_APP_API_BASE}/GetInverters?SmartLoggerSN=${device}`,
            config
          );
          const inverters = invertersResponse.data;

          const finalInverters = await Promise.all(
            inverters.map(async (inverter) => {
              const [stringsResponse, latestDataResponse] = await Promise.all([
                axios.get(
                  `${env.REACT_APP_API_BASE}/GetInverterStrings?InverterSN=${inverter.inverterSN}`,
                  config
                ),
              ]);

              return {
                inverter: inverter,
                strings: stringsResponse.data,
              };
            })
          );

          return {
            sn: device,
            inverters: finalInverters,
          };
        })
      );

      setDevicesList(finalDevices);
    } catch (error) {
      console.error("Failed to fetch device data:", error);
    }

    setDevicesListLoaded(true);
  };

  const refreshInverterLogs = async (inverter) => {
    if (inverter) {
      let logs = await axios.get(
        `${env.REACT_APP_API_BASE}/GetInverterLatestData?InverterSN=${inverter.inverter.inverterSN}`,
        config
      );
      setSelectedInverterLogs(logs.data);
    }
  };

  useEffect(() => {
    fetchPlantData();
      fetchMonthlyData();
      fetchDevicesData();
  }, []);

  useEffect(() => {
    fetchPowerData();
    fetchMonthlyDetailsData();
  }, [date]);

  useEffect(() => {
    const isDarkTheme = document.documentElement.getAttribute("data-bs-theme");
    if (isDarkTheme === "dark") darkTheme(Highcharts);
    else lightTheme(Highcharts);
  }, []);

  const calculateDailyPower = () => {
    if (powerData.length === 0) return 0;
    let total = 0;
    powerData.forEach((item) => (total += item.generatedEnergy));
    return total;
  };

  const calculateDailyRevenue = () => {
    if (powerData.length === 0) return 0;
    let total = 0;
    powerData.forEach((item) => (total += item.generatedRevenue));
    return total;
  };

  const productionOptions = {
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: minDate,
      max: maxDate,
      tickInterval: 4 * 3600 * 1000,
      labels: {
        format: "{value:%H:%M}", // Format the x-axis labels as HH:MM
      },
      title: {
        text: "Time",
        align: "high",
      },
    },
    yAxis: [
      {
        title: {
          text: "Power (kW)",
        },
        labels: {
          style: { fontSize: 10 },
          format: "{value}", // Format the x-axis labels as HH:MM
        },
      },
    ],
    tooltip: {
      valueDecimals: 2, // Set the number of decimals for tooltip values
    },
    series: [
      {
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, "#55BF3A"],
            [0, Highcharts.color("#55BF3A").setOpacity(0.1).get("rgba")],
          ],
        },
        name: "Power",
        data: Array.isArray(powerData)
          ? powerData.map((item) => [
              Date.parse(item.eventTimestamp),
              parseFloat(item.power),
            ])
          : [],
        type: "area",
        yAxis: 0,
        color: "#55BF3A",
        lineWidth: 2,
      },
    ],
    time: {
      timezoneOffset: new Date().getTimezoneOffset(),
    },
    credits: {
      enabled: false, // Disable the Highcharts hyperlink
    },
    legend: {
      enabled: false,
    },
    chart: {
      zoomType: "x",
      backgroundColor: "transparent",
      color: "#BA74FF",
      borderWidth: 0,
    },
  };

  const energyOptions = {
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      backgroundColor: "transparent",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      minWidth: 180,
      height: "100%",
    },

    credits: {
      enabled: false,
    },

    title: {
      text: "",
    },

    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ["50%", "75%"],
      size: "80%",
    },

    // the value axis
    yAxis: {
      min: 0,
      max:
        calculateDailyPower() > 1000
          ? Math.ceil(calculateDailyPower() / (1000 * 5)) * 5
          : Math.ceil(calculateDailyPower() / 100) * 100,
      tickPixelInterval: 72,
      tickPosition: "inside",
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: "14px",
        },
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to:
            calculateDailyPower() > 1000
              ? Math.ceil(calculateDailyPower() / (1000 * 5)) * 5
              : Math.ceil(calculateDailyPower() / 100) * 100,
          color: "#55BF3B", // green
          thickness: 20,
        },
      ],
    },

    series: [
      {
        name: "Energy",
        data: [
          calculateDailyPower() > 1000
            ? Math.round(calculateDailyPower() / 10) / 100
            : Math.round(calculateDailyPower() * 100) / 100,
        ],
        tooltip: {
          valueSuffix: calculateDailyPower() > 1000 ? " MWh" : " kWh",
        },
        dataLabels: {
          format:
            "Energy yield: " +
            (calculateDailyPower() > 1000 ? "{y} MWh" : "{y} kWh"),
          borderWidth: 0,
          color:
            (Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "#333333",
          style: {
            fontSize: "16px",
          },
        },
        dial: {
          radius: "80%",
          backgroundColor: "#55BF3B",
          baseWidth: 12,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "transparent",
          radius: 6,
        },
      },
    ],
  };

  const revenueOptions = {
    chart: {
      type: "gauge",
      plotBackgroundColor: null,
      backgroundColor: "transparent",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      minWidth: 180,
      height: "100%",
    },

    credits: {
      enabled: false,
    },

    title: {
      text: "",
    },

    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ["50%", "75%"],
      size: "80%",
    },

    // the value axis
    yAxis: {
      min: 0,
      max: Math.ceil(calculateDailyRevenue() / 100) * 100,
      tickPixelInterval: 72,
      tickPosition: "inside",
      tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: "14px",
        },
      },
      lineWidth: 0,
      plotBands: [
        {
          from: 0,
          to: Math.ceil(calculateDailyRevenue() / 100) * 100,
          color: "#BA74FF", // green
          thickness: 20,
        },
      ],
    },

    series: [
      {
        name: "Revenue",
        data: [Math.round(calculateDailyRevenue() * 100) / 100],
        tooltip: {
          valueSuffix: " EUR",
        },
        dataLabels: {
          format: "Revenue: {y} EUR",
          borderWidth: 0,
          color:
            (Highcharts.defaultOptions.title &&
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "#333333",
          style: {
            fontSize: "16px",
          },
        },
        dial: {
          radius: "80%",
          backgroundColor: "#BA74FF",
          baseWidth: 12,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "transparent",
          radius: 6,
        },
      },
    ],
  };

  const monthlyEnergyConfig = {
    chart: {
      type: "column",
      backgroundColor: null,
    },
    title: {
      text: "Monthly summary:",
      align: "center",
    },
    subtitle: null,
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Array.isArray(monthlyDetails)
        ? monthlyDetails.map(
            (item) =>
              "Day " + parseInt(item.forDate.split("T")[0].split("-")[2])
          )
        : [],
      crosshair: true,
      accessibility: {
        description: "Day of month",
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value} kWh",
          style: {
            color: "#55BF3A",
          },
        },
        title: {
          text: null,
          style: {
            color: "#55BF3A",
          },
        },
      },
    ],
    tooltip: {
      valueSuffix: " kWh",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Generated Energy",
        data: Array.isArray(monthlyDetails)
          ? monthlyDetails.map((item) => Math.round(item.generatedEnergy))
          : [],
        color: "#55BF3A",
      },
    ],
  };

  const monthlyRevenueConfig = {
    chart: {
      type: "column",
      backgroundColor: null,
    },
    title: {
      text: "Monthly summary:",
    },
    subtitle: null,
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: Array.isArray(monthlyDetails)
        ? monthlyDetails.map(
            (item) =>
              "Day " + parseInt(item.forDate.split("T")[0].split("-")[2])
          )
        : [],
      crosshair: true,
      accessibility: {
        description: "Day of month",
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value} EUR",
          style: {
            color: "#BA74FF",
          },
        },
        title: {
          text: null,
          style: {
            color: "#BA74FF",
          },
        },
      },
    ],
    tooltip: {
      valueSuffix: " EUR",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Generated Revenue",
        data: Array.isArray(monthlyDetails)
          ? monthlyDetails.map(
              (item) => Math.round(item.generatedRevenue * 100) / 100
            )
          : [],
        color: "#BA74FF",
      },
    ],
  };

  function handleDateChange(newDate) {
    setIsDataLoading(true);
    if (newDate === null) newDate = new Date();
    console.log(newDate);
    setDate(newDate);
    setMinDate(newDate.setHours(0, 0, 0, 0));
    setMaxDate(newDate.setHours(23, 59, 59, 999));
  }
  {
    /* Top of page */
  }
  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <>
      <div
        className="bg-body-tertiary m-0 p-0 w-100 d-flex flex-column justify-content-center align-items-center text-center"
        style={{ height: "160px" }}
      >
        <div className="w-100 d-flex flex-row">
          <Link
            className="btn"
            to={"/company?id=" + plantData.ownerID}
            style={{ fontSize: 26, border: "none", marginLeft: "12px" }}
          >
            <BsArrowLeft />
          </Link>
        </div>
        <h1 style={{ fontFamily: "Rajdhani" }}>{plantData.name}</h1>
        <h5 className="text-muted">
          {plantData.address}, {plantData.zip} {plantData.city},{" "}
          {plantData.country}
        </h5>
      </div>
      <TabView className="m-0 p-0">
        <TabPanel header="Overview" leftIcon="pi pi-home icon">
          <div className="row w-100 ms-auto me-auto">
            <div
              className="col col-12 bg-body-tertiary p-2 my-1 text-center custom-card"
              style={{ borderRadius: "16px" }}
            >
              <span style={{ fontWeight: "bold" }}>Installed Capacity: </span>
              <br />
              <span>{plantData.installedCapacity} kW</span>
            </div>
            <div
              className="col col-12 bg-body-tertiary p-2 my-1 text-center custom-card"
              style={{ borderRadius: "16px" }}
            >
              <span style={{ fontWeight: "bold" }}>Monthly production: </span>
              <br />
              <span>
                {isMonthlyLoading ? "-----" : monthlyData.mwh.toFixed(2)} MWh
              </span>
            </div>
            <div
              className="col col-12 bg-body-tertiary p-2 my-1 text-center custom-card"
              style={{ borderRadius: "16px" }}
            >
              <span style={{ fontWeight: "bold" }}>Monthly revenue: </span>
              <br />
              <span>
                {isMonthlyLoading ? "----.--" : monthlyData.eur.toFixed(2)} €
              </span>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Production" leftIcon="pi pi-chart-bar icon">
          {isDataLoading ? (
            // Display the spinner while data is loading
            "Loading"
          ) : (
            // Render the chart once the data has loaded
            <div
              className="w-100 p-2 bg-body-tertiary ms-auto d-flex flex-column me-auto align-items-center justify-content-center"
              style={{ borderRadius: "16px" }}
            >
              <DatePicker
                selected={date}
                dateFormat={"dd/MM/yyyy"}
                isClearable
                onChange={handleDateChange}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={productionOptions}
                containerProps={{ style: { width: "80vw", height: "45vh" } }}
              />
              {monthlyDetailsLoaded ? (
                <>
                  <div className="row w-100 mt-2 ms-auto me-auto">
                    <div className="col col-12 col-md-6 d-flex align-items-center justify-content-center">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={energyOptions}
                        containerProps={{
                          style: { width: "min(350px, max(50vw, 220px))" },
                        }}
                      />
                    </div>
                    <div className="col col-12 col-md-6 d-flex align-items-center justify-content-center">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={monthlyEnergyConfig}
                        containerProps={{
                          style: { width: "min(500px, max(50vw, 220px))" },
                        }}
                      />
                    </div>
                  </div>
                  <div className="row w-100 mt-2 mx-auto text-center">
                    <h4>
                      Total production of {formattedMonthDate}:{" "}
                      {totalMonthlyProduction > 1000
                        ? Math.round(totalMonthlyProduction / 10) / 100
                        : Math.round(totalMonthlyProduction * 100) / 100}
                         
                         {
                          totalMonthlyProduction > 1000?" MWh":" kWh"
                         }
                    </h4>
                  </div>
                </>
              ) : (
                <h3>Loading monthly details...</h3>
              )}
            </div>
          )}
        </TabPanel>
        <TabPanel header="Revenue" leftIcon="pi pi-euro icon">
          <div
            className="w-100 p-2 bg-body-tertiary ms-auto me-auto d-flex flex-column me-auto align-items-center justify-content-center"
            style={{ borderRadius: "16px" }}
          >
            <DatePicker
              selected={date}
              dateFormat={"dd/MM/yyyy"}
              isClearable
              onChange={handleDateChange}
            />

            {monthlyDetailsLoaded ? (
              <>
                <div className="row w-100 mt-2 ms-auto me-auto">
                  <div className="col col-12 col-md-6 d-flex align-items-center justify-content-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={revenueOptions}
                      containerProps={{
                        style: { width: "min(350px, max(50vw, 220px))" },
                      }}
                    />
                  </div>
                  <div className="col col-12 col-md-6 d-flex align-items-center justify-content-center">
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={monthlyRevenueConfig}
                      containerProps={{
                        style: { width: "min(500px, max(50vw, 220px))" },
                      }}
                    />
                  </div>
                  <div className="row w-100 mt-2 mx-auto text-center">
                    <h4>
                      Total revenue of {formattedMonthDate}{": "}
                      {Math.round(totalMonthlyRevenue*100)/100} EUR
                    </h4>
                  </div>
                </div>
              </>
            ) : (
              <h3>Loading monthly details...</h3>
            )}
          </div>
        </TabPanel>
        <TabPanel header="Devices" leftIcon="pi pi-bars icon">
          {devicesListLoaded ? (
            <div
              className="w-100 p-4 bg-body-tertiary ms-auto me-auto d-flex flex-column me-auto justify-content-center"
              style={{ borderRadius: "16px" }}
            >
              <ul className="list-group">
                {!selectedDevice &&
                  !selectedInverter &&
                  !selectedInverterLogs && (
                    <>
                      <h1>SmartLoggers</h1>
                      {devicesList.map((obj) => (
                        <li
                          className={
                            obj === selectedDevice
                              ? "list-group-item active"
                              : "list-group-item"
                          }
                          key={obj.sn}
                          onClick={() => setSelectedDevice(obj)}
                        >
                          {obj.sn}
                        </li>
                      ))}
                    </>
                  )}

                {selectedDevice &&
                  !selectedInverter &&
                  !selectedInverterLogs && (
                    <div>
                      <h2>Inverters in {selectedDevice.sn}</h2>
                      <button
                        className="btn btn-primary m-2"
                        onClick={() => {
                          setSelectedDevice(null);
                          setSelectedInverter(null);
                          setSelectedInverterLogs(null);
                        }}
                      >
                        Back to SmartLoggers
                      </button>
                      <ul className="list-group">
                        {selectedDevice.inverters
                          .sort((a, b) => {
                            const nameA = a.inverter.name.match(/\d+/); // find numbers in the string
                            const nameB = b.inverter.name.match(/\d+/);

                            if (nameA && nameB) {
                              return parseInt(nameA[0]) - parseInt(nameB[0]);
                            }

                            return a.inverter.name.localeCompare(
                              b.inverter.name
                            );
                          })
                          .filter((subObj) => {
                            return subObj.inverter.name;
                          })
                          .map((subObj) => (
                            <li
                              className={
                                subObj === selectedInverter
                                  ? "list-group-item active"
                                  : "list-group-item"
                              }
                              key={subObj.inverter.inverterSN}
                              onClick={() => {
                                setSelectedInverter(subObj);
                                refreshInverterLogs(subObj);
                              }}
                            >
                              {subObj.inverter.name !== ""
                                ? subObj.inverter.name +
                                  " - SN: " +
                                  subObj.inverter.inverterSN
                                : subObj.inverter.inverterSN}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
              </ul>

              {selectedDevice && selectedInverter && selectedInverterLogs && (
                <div>
                  <h1>
                    {selectedInverter.inverter.name
                      ? `${selectedInverter.inverter.name} - SN:${selectedInverter.inverter.inverterSN}`
                      : selectedInverter.inverter.inverterSN}
                  </h1>
                  <h4>Plant: {selectedInverterLogs.instanceID}</h4>
                  <h4>Yield today: {selectedInverterLogs.e_Day}kWh</h4>
                  <p>Date: {selectedInverterLogs.eventTimestamp}</p>
                  <p>Status: {selectedInverterLogs.status}</p>
                  <p>Error: {selectedInverterLogs.error}</p>
                  <p>Temperature: {selectedInverterLogs.temp}°C</p>
                  <button
                    className="btn btn-primary m-2"
                    onClick={() => {
                      setSelectedInverter(null);
                      setSelectedInverterLogs(null);
                    }}
                  >
                    Back to Inverters
                  </button>
                  <h2>DC Input:</h2>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>String ID</th>
                        <th>String Number</th>
                        <th>Active</th>
                        <th>Number of panels</th>
                        <th>UPV</th>
                        <th>IPV</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedInverter.strings.map((item, index) => (
                        <tr
                          key={index}
                          style={{
                            backgroundColor:
                              item.active === 1 &&
                              selectedInverterLogs[`ipv${item.stringID}`] < 1
                                ? "red"
                                : "",
                          }}
                        >
                          <td>{item.stringID}</td>
                          <td>{item.stringNumber}</td>
                          <td>{item.active === 1 ? "Active" : "Inactive"}</td>
                          <td>{item.numberOfPanels}</td>
                          <td>{selectedInverterLogs[`upv${item.stringID}`]}</td>
                          <td>{selectedInverterLogs[`ipv${item.stringID}`]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h2>AC Output:</h2>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Grid Voltage (V)</th>
                        <th>Grid Current (A)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{selectedInverterLogs.uac1}V</td>
                        <td>{selectedInverterLogs.iac1}A</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>{selectedInverterLogs.uac2}V</td>
                        <td>{selectedInverterLogs.iac2}A</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>{selectedInverterLogs.uac3}V</td>
                        <td>{selectedInverterLogs.iac3}A</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ) : (
            <p>Loading</p>
          )}
        </TabPanel>
      </TabView>
    </>
  );
}

export default Plant;
