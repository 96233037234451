import React from 'react';
import preval from 'preval.macro';

const Footer = () => {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) + ' | ' + Intl.DateTimeFormat().resolvedOptions().timeZone;`
  return (
    <footer className='row' style={styles.footer}>
      <div className='col col-12 col-md-6'>
        <span>&copy; {new Date().getFullYear()} EnergyMon. All rights reserved.</span>
      </div>
      <div className='col col-12 col-md-6'>
        <span>Build date: {dateTimeStamp}</span>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: 'var(--bs-tertiary-bg)',
    color: 'var(--bs-body-color)',
    textAlign: 'center',
    fontSize: '14px',
    width: '100vw',
    margin:'0',
    paddingTop: '12px',
    paddingBottom: '12px',
    position: 'fixed', // Keep this property to make the footer sticky
    bottom: 0,        // Keep this property to make the footer stick to the bottom
    left: 0,          // Keep this property to align the footer to the left
  },
};

export default Footer;
