import React from 'react';
import { useSignOut, useIsAuthenticated } from "react-auth-kit";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import preval from 'preval.macro'


const NavigationBar = () => {
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();

    const handleLogout = () => {
      localStorage.removeItem('remember')
      localStorage.removeItem('username')
      localStorage.removeItem('password')
      localStorage.setItem('remember', false)
      signOut()
    }

  return (
    <Navbar expand="md" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/" style={{fontFamily:'Rajdhani'}}><img src='/logo-small.png' style={{width:32, marginRight: 15, marginLeft: 20}}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {isAuthenticated()?
                <>
                  <Nav.Link href="/profile">My profile</Nav.Link>
                  <Nav.Item><button className='nav-link' onClick={handleLogout}>Log out</button></Nav.Item>
                </>
              :
              <>
                <Nav.Link href="/login">Log in</Nav.Link>
                <Nav.Link href="/register">Register</Nav.Link>
              </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
