import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

const Registration = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShowState, setPasswordShowState] = useState("password");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleRegistration = async () => {
    try {
      // Basic validation checks
      if (!email || !password || password !== confirmPassword) {
        throw new Error("Please fill in all fields and ensure passwords match.");
      }

      // Additional validation checks can be added here, such as password complexity

      const response = await axios.post(
        process.env.REACT_APP_API_BASE + "/CreateUser",
        { Username: email, email: email, password: password }
      );

      if (
        !response ||
        response.data.resultMessage !== "User has been created."
      ) {
        throw new Error(response.data.resultMessage);
      }

      // Registration successful, navigate to the success page
        navigate("/registrationSuccess");

      setEmail("");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Registration failed:", error);
      setErrorMessage(error.message || "Registration failed. Please try again.");
    }
  };

  function switchPasswordView() {
    if (passwordShowState === "password") 
      setPasswordShowState("text");
    else setPasswordShowState("password");
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center m-3"
      style={{ height: "calc(100vh - 96px)" }}
    >
      <h1 style={{ fontFamily: "Rajdhani", fontSize: "72px" }}>Register to EnergyMon</h1>

      <div style={{ minHeight: "1.5em" }}>
        <p className="text-danger">{errorMessage}</p>
      </div>
      <Form style={{ width: "calc(min(600px, 90vw))" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={handleEmailChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <div className="input-group mb-3">
            <Form.Control
              type={passwordShowState}
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={switchPasswordView}
                style={{borderRadius: "0 5px 5px 0"}}
              >
                {passwordShowState === "password" ? (
                  <BsEyeFill />
                ) : (
                  <BsEyeSlashFill />
                )}
              </button>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type={passwordShowState}
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </Form.Group>

        <Button variant="primary" type="button" style={{background: '#771FFF', border: 0}} onClick={handleRegistration}>
          Register
        </Button>
      </Form>
    </div>
  );
};

export default Registration;
