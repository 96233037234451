import React from "react";

const RegistrationSuccess = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-3">
      <h1>Registration Successful</h1>
      <p>Please check your email for further instructions.</p>
    </div>
  );
};

export default RegistrationSuccess;
