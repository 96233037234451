import React from "react";

const Invalid = () => {
    return(
        <div className="container w-100 d-flex flex-column justify-content-center align-items-center m-3" style={{height:'calc(100vh - 96px)'}}>
            <h1 style={{fontFamily:'Rajdhani', fontSize: '96px'}}>Error 404</h1>
            <h3>This path is invalid</h3>
            <a href="/" className="btn btn-primary">Go to home</a>
        </div>
    )
}

export default Invalid;