import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { env } from '../env'

const Login = () => {
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [password, setPassword] = useState(localStorage.getItem("password"));
  const [passwordShowState, setPasswordShowState] = useState("password");
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("remember")
  );
  const [shouldAutoLogin, setShouldAutoLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState(" ");
  
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleRememberChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const signIn = useSignIn();

  const attemptAutoLogin = async () => {
    try {
      const response = await axios.post(
        env.REACT_APP_API_BASE + "/LoginUser",
        { username: username, password: password }
      );
      if (
        !response ||
        response.data.resultMessage.split(" ")[1] !== "successfull."
      )
        throw new Error(response.data.resultMessage);

      await signIn({
        token: response.data.resultMessage.split(" ")[3],
        expiresIn: 60,
        tokenType: "Bearer",
        authState: rememberMe
          ? { username: username, password: password }
          : { username: username },
      });

      setErrorMessage(" ");

      navigate("/");
    } catch (error) {
      console.error("Auto login failed:", error);

      // Set the error message based on the API response
      setErrorMessage("Login failed. Please check your credentials.");
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        env.REACT_APP_API_BASE + "/LoginUser",
        { username: username, password: password }
      );

      if (
        !response ||
        response.data.resultMessage.split(" ")[1] !== "successfull."
      )
        throw new Error(response.data.resultMessage);

      if (rememberMe) {
        localStorage.setItem("remember", true);
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
      } else {
        localStorage.setItem("remember", false);
        localStorage.setItem("username", "");
        localStorage.setItem("password", "");
      }

      await signIn({
        token: response.data.resultMessage.split(" ")[3],
        expiresIn: 60,
        tokenType: "Bearer",
        authState: rememberMe
          ? { username: username, password: password }
          : { username: username },
      });

      setErrorMessage(" ");

      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      // If the login fails, you might want to consider resetting the
      // shouldAutoLogin flag to prevent further automatic attempts.

      console.error("Login failed:", error);

      // Set the error message based on the API response
      setErrorMessage("Login failed. Please check your credentials.");

      setShouldAutoLogin(false);
    }
  };

  useEffect(() => {
    let rememberMeStorage = localStorage.getItem("remember");
    if (rememberMeStorage === "true") {
      console.log("Setting flag for auto login...");
      setShouldAutoLogin(true);
    }
  }, []);

  useEffect(() => {
    if (shouldAutoLogin) {
      console.log("Attempting auto login...");
      attemptAutoLogin();
    }
  }, [shouldAutoLogin]);

  function switchPasswordView() {
    if (passwordShowState === "password") 
      setPasswordShowState("text");
    else setPasswordShowState("password");
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center m-3"
      style={{ height: "calc(100vh - 96px)" }}
    >
      <h1 style={{ fontFamily: "Rajdhani", fontSize: "72px" }}>Login</h1>

      <div style={{ minHeight: "1.5em" }}>
        <p className="text-danger">{errorMessage}</p>
      </div>
      <Form style={{ width: "calc(min(600px, 90vw))" }}>
        <Form.Group className="mb-3" controlId="formBasicUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            onChange={handleUsernameChange}
          />
          <Form.Text className="text-muted">
            We'll never share your username with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <div className="input-group mb-3">
            <Form.Control
              type={passwordShowState}
              placeholder="Password"
              onChange={handlePasswordChange}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={switchPasswordView}
                style={{borderRadius: "0 5px 5px 0"}}
              >
                {passwordShowState === "password" ? (
                  <BsEyeFill />
                ) : (
                  <BsEyeSlashFill />
                )}
              </button>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="d-flex flex-row mb-3" controlId="formRememberMe">
          <Form.Check
            type="checkbox"
            className="pe-2"
            onChange={handleRememberChange}
          ></Form.Check>
          <span>Remember me</span>
        </Form.Group>

        <Button
          variant="primary"
          type="button"
          onClick={handleSubmit}
          style={{ background: "#771FFF", border: 0 }}
        >
          Log in
        </Button>
      </Form>
    </div>
  );
};

export default Login;
