import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState, useCallback } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { Card } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { SlEnergy } from "react-icons/sl";
import { FaEuroSign } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";
import { LuAlignVerticalDistributeStart } from "react-icons/lu";
import "./Company.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { env } from "../env";

const Company = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  let [searchParams, setSearchParams] = useSearchParams();

  const [companyDataLoaded, setCompanyDataLoaded] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  const [plantsDataLoaded, setPlantsDataLoaded] = useState(false);
  const [plantsData, setPlantsData] = useState([]);
  const [plantsView, setPlantsView] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [summaryData, setSummaryData] = useState({})
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const getTotalProduction = (data) => {
    let total = 0;
    data.forEach((plant) => (total += plant.mwh));
    return total;
  };

  const getTotalRevenue = (data) => {
    let total = 0;
    data.forEach((plant) => (total += plant.eur));
    return total;
  };

  const getTotalDailyProduction = (data) => {
    let total = 0;
    data.forEach((plant) => (total += plant.dailyMwh));
    return total;
  };

  const getTotalDailyRevenue = (data) => {
    let total = 0;
    data.forEach((plant) => (total += plant.dailyEur));
    return total;
  };

  const config = {
    headers: {
      Token: Cookies.get("auth"),
    },
  };

  async function GetCompany() {
    if (isAuthenticated()) {
      try {
        const companyResponse = await axios.get(
          env.REACT_APP_API_BASE + "/GetCompanies?id=" + searchParams.get("id"),
          config
        );
        const companyData = companyResponse.data[0];

        const plantsResponse = await axios.get(
          env.REACT_APP_API_BASE +
            "/GetPlants?companyId=" +
            searchParams.get("id"),
          config
        );
        const plantsData = plantsResponse.data;

        const monthString =
          selectedDate.getMonth() < 9
            ? "0" + (selectedDate.getMonth() + 1)
            : selectedDate.getMonth() + 1;
        const yearString = selectedDate.getYear() + 1900;
        const monthYearString = monthString + "/" + yearString;
        const dayString = (selectedDate.getDate()<10?'0'+selectedDate.getDate():selectedDate.getDate()) + "/" + monthYearString;
        // console.log(dayString);
        const productionPromises = plantsData.map(async (plant) => {
          const url =
            env.REACT_APP_API_BASE +
            "/GetProduction?plantId=" +
            plant.id +
            "&type=monthlySummary&parameter=" +
            monthYearString;
          try {
            const response = await axios.get(url, config);
            return response; // Return the entire response object
          } catch (error) {
            console.error("Error fetching production data:", error);
            navigate("/login?retry=true");
          }
        });

        const productionResponses = await Promise.all(productionPromises);

        const dayProductionPromises = plantsData.map(async (plant) => {
          const url =
            env.REACT_APP_API_BASE +
            "/GetProduction?plantId=" +
            plant.id +
            "&type=dailySummary&parameter=" +
            dayString;
          try {
            const response = await axios.get(url, config);
            return response; // Return the entire response object
          } catch (error) {
            console.error("Error fetching production data:", error);
            navigate("/login?retry=true");
          }
        });

        const dayProductionResponses = await Promise.all(dayProductionPromises);
        const findDayProductionResponseById = (id) => {
          let filter = dayProductionResponses.filter((p) => {
            // console.log(p.data)
            return p.data.length > 0 && p.data[0].plantID && !isNaN(p.data[0].plantID) && p.data[0].plantID === id;
          });
          // console.log(filter[0].data[0])
          if(filter.length === 0){
            return {
              productionData: {
                generatedEnergy: 0,
                generatedRevenue: 0,
              },
            }
          }

          return (
            filter[0]?.data[0]
          );
        };

        let updatedPlantsData = plantsData.map((plant, index) => {
          if (productionResponses[index].data.length === 0) {
            return {
              eventTimestamp: "2023-09-07T05:00:00",
              id: parseInt(
                productionResponses[index].config.url
                  .split("GetProduction?plantId=")[1]
                  .split("&")[0]
              ),
              mwh: 0,
              eur: 0,
              power: 0,
            };
          }
          const productionData = productionResponses[index].data[0];
          return {
            ...plant,
            eur: productionData.eur??0,
            mwh: productionData.mwh??0,
          };
        });

        updatedPlantsData = updatedPlantsData.map((plant, index) => {
          if (dayProductionResponses[index].data.length === 0) {
            return {
              eventTimestamp: "2023-09-07T05:00:00",
              id: parseInt(
                dayProductionResponses[index].config.url
                  .split("GetProduction?plantId=")[1]
                  .split("&")[0]
              ),
              dailyMwh: 0,
              dailyEur: 0,
              dailyPower: 0,
            };
          }
          // console.log(plant)
          const productionData = findDayProductionResponseById(plant.id);
          // console.log(productionData)
          return {
            ...plant,
            dailyEur: productionData?.generatedRevenue,
            dailyMwh: productionData?.generatedEnergy / 1000,
          };
        });

        setCompanyData(companyData);
        setCompanyDataLoaded(true);
        setSummaryData({
          totalEnergy: getTotalProduction(updatedPlantsData),
          totalRevenue: getTotalRevenue(updatedPlantsData),
          dailyEnergy: getTotalDailyProduction(updatedPlantsData),
          dailyRevenue: getTotalDailyRevenue(updatedPlantsData),
        })
        // console.log(updatedPlantsData);
        await setPlantsData(updatedPlantsData);

        const plantsView = updatedPlantsData.map((plant) => (
          <Card
            className="w-100 mb-3 custom-card"
            as={Link}
            to={"/plant?id=" + plant.id}
            key={plant.id}
          >
            <Card.Body>
              <div className="d-flex flex-row">
                <div className="plant-image-container d-flex flex-col w-1/4">
                  <img
                    src="/sample-plant.jpg"
                    className="plant-image mb-3"
                    alt={plant.name}
                  />
                  <div className="col-12 align-items-center justify-content-center p-0 mb-3">
                    <span style={{ fontSize: "14px" }}>Status:</span>
                    <span
                      className="btn btn-outline-success disabled px-2 py-1 mx-2"
                      style={{
                        width: "60px",
                        height: "30px",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Active
                    </span>
                  </div>
                  <div className="col-12 col-md-12 d-flex flex-row justify-center">
                    <LuAlignVerticalDistributeStart className="my-auto" />{" "}
                    <p className="my-auto">{plant.installedCapacity} kW</p>
                  </div>
                </div>
                <div className="plant-details w-3/4">
                  <Card.Title className="plant-name">{plant.name}</Card.Title>
                  <p>Monthly:</p>
                  <div className="row plant-stats ps-1">
                    <div className="col-6 align-items-center justify-content-center d-flex flex-row p-0">
                      <SlEnergy className="my-auto" />{" "}
                      <p className="my-auto">{(plant.mwh??0).toFixed(2)} MWh</p>
                    </div>
                    <div className="col-6 align-items-center justify-content-center d-flex flex-row p-0">
                      <FaEuroSign className="my-auto" />{" "}
                      <p className="my-auto">{(plant.eur??0).toFixed(0)} EUR</p>
                    </div>
                  </div>
                  <hr className="my-1" />
                  <p>Daily:</p>
                  <div className="row plant-stats ps-1">
                    <div className="col-6 align-items-center justify-content-center d-flex flex-row p-0">
                      <SlEnergy className="my-auto" />{" "}
                      <p className="my-auto">{(plant.dailyMwh??0).toFixed(2)} MWh</p>
                    </div>
                    <div className="col-6 align-items-center justify-content-center d-flex flex-row p-0">
                      <FaEuroSign className="my-auto" />{" "}
                      <p className="my-auto">{(plant.dailyEur??0).toFixed(0)} EUR</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        ));

        setPlantsView(plantsView);
        setPlantsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/login?retry=true");
      }
    }
  }

  useEffect(() => {
    try {
      GetCompany();
    } catch {
      return <p>Sorry, an error occured</p>;
    }
  }, []);

  const getSummary = () => {
    return (<div className="mb-3" key={summaryData}>
    <p className="text-sm lg:text-2xl font-bold mb-0">
      Monthly:
    </p>
    <p className="text-xs sm:text-sm lg:text-2xl font-bold mb-0">
      <span className="border-r-2 border-gray-400 pe-1 me-1">
        {(summaryData?.totalEnergy && !isNaN(summaryData.totalEnergy)?summaryData.totalEnergy:0).toFixed(2)} MWh
      </span>
      <span className="border-r-2 border-gray-400 pe-1 me-1">
        {(summaryData?.totalRevenue && !isNaN(summaryData.totalRevenue)?summaryData.totalRevenue:0).toFixed(0)} EUR
      </span>
      {(summaryData.totalEnergy?(summaryData.totalRevenue / summaryData.totalEnergy):0).toFixed(2)}{" "}
      EUR/MWh
    </p>
    <hr className="my-1" />
    <p className="text-sm lg:text-2xl font-bold mb-0">
      Daily:
    </p>
    <p className="text-xs sm:text-sm lg:text-2xl font-bold mb-0">
      <span className="border-r-2 border-gray-400 pe-1 me-1">
        {(summaryData.dailyEnergy && !isNaN(summaryData.dailyEnergy)?summaryData.dailyEnergy:0).toFixed(2)} MWh
      </span>
      <span className="border-r-2 border-gray-400 pe-1 me-1">
        {(summaryData.dailyRevenue && !isNaN(summaryData.dailyRevenue)?summaryData.dailyRevenue:0).toFixed(0)} EUR
      </span>
      {(
        isNaN(summaryData.dailyRevenue / summaryData.dailyEnergy)?0:summaryData.dailyRevenue / summaryData.dailyEnergy
      ).toFixed(2)}{" "}
      EUR/MWh
    </p>
  </div>)
  }

  async function handleDateChange(newDate) {
    if (newDate === null) newDate = new Date();
    // console.log(newDate);
    setSelectedDate(newDate);
    setPlantsDataLoaded(false);
    await GetCompany();
    setPlantsDataLoaded(true);
  }

  return (
    <div className="bg-body-tertiary m-0 p-0">
      <div className="container-fluid w-100 d-flex flex-column align-items-center text-center mt-0 p-0">
        {companyDataLoaded ? (
          companyData.length === 0 ? (
            <h1>The company doesn't exist</h1>
          ) : (
            <>
              <div className="w-100 text-center d-flex flex-column align-items-center justify-content-center">
                <div className="w-100 bg-body-tertiary">
                  <div className="w-100 d-flex align-items-start">
                    <Link
                      className="btn"
                      to={"/"}
                      style={{
                        fontSize: 26,
                        border: "none",
                        marginLeft: "12px",
                      }}
                    >
                      <BsArrowLeft />
                    </Link>
                  </div>
                  <div className="d-flex flex-column py-3 px-2">
                    <h1 className="text-lg sm:text-2xl text-uppercase lg:text-3xl">
                      {companyData.name}
                    </h1>
                  </div>
                  <div className="d-flex flex-row mb-3 items-center justify-center">
                    <button
                      onClick={() => {
                        let tmpDate = selectedDate;
                        tmpDate.setDate(tmpDate.getDate() - 1);
                        handleDateChange(tmpDate);
                      }}
                      className="mx-2"
                    >
                      {"<"}
                    </button>
                    <DatePicker
                      selected={selectedDate}
                      dateFormat={"dd/MM/yyyy"}
                      isClearable
                      onChange={handleDateChange}
                    />
                    <button
                      onClick={() => {
                        let tmpDate = selectedDate;
                        tmpDate.setDate(tmpDate.getDate() + 1);
                        handleDateChange(tmpDate);
                      }}
                      className="mx-2"
                    >
                      {">"}
                    </button>
                  </div>
                </div>
                {plantsDataLoaded ? (
                  <div className="bg-body w-100 pt-4  d-flex flex-column align-items-center justify-content-center">
                    {getSummary()}
                    <div className="w-[90%]">{plantsView}</div>
                  </div>
                ) : (
                  "Loading"
                )}
              </div>
            </>
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};

export default Company;
