import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const RegistrationConfirmation = ({ location }) => {
  const [errorMessage, setErrorMessage] = useState("Confirming email...");
  const [searchParams] = useSearchParams();

  let completeRegistration = false;
  
  useEffect(() => {
    const confirmationId = searchParams.get("Id");

    if (!confirmationId) {
      setErrorMessage("Invalid confirmation link. Please check your email or contact support.");
    } else {
      // Send the confirmationId to the backend for registration confirmation
      confirmRegistration(confirmationId);
    }
  }, []);

  const confirmRegistration = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE + "/confirmEmail?Id="+id,
      );

      // Handle the response from the backend if needed
      console.log("Registration confirmed:", response.data);
    } catch (error) {
      // Handle the error if registration confirmation fails
      console.error("Registration confirmation failed:", error);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-3">
      <h1>Registration Confirmation</h1>
      {completeRegistration ? (
        <p className="text-danger">{errorMessage}</p>
      ) : (
        <p>Your registration has been confirmed. Please <a href='/login'>log in</a>.</p>
      )}
    </div>
  );
};

export default RegistrationConfirmation;
